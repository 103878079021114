<template>
  <div class="d-flex">
    <div class="preview_container print-card">
      <div class="text-center">
        <v-progress-circular
          v-if="loading"
          class="my-15"
          :size="50"
          color="primary"
          indeterminate
        />
      </div>

      <div class="preview_content" v-if="!loading">
        <div v-if="show_ids" style="position: absolute; top: -20px;">
          <v-chip x-small> #{{ article.id }} </v-chip>
        </div>
        <div class="preview_date_box">
          <div v-if="article.assigned_date" class="preview_day">
            {{ article.assigned_date | luxon("d") }}
          </div>
          <div v-else class="preview_day">00</div>
          <div v-if="article.assigned_date" class="preview_weekday">
            {{ article.assigned_date | luxon("EEEE") }}
          </div>
          <div v-else>WOCHENTAG</div>
          <div v-if="article.assigned_date" class="preview_month">
            {{ article.assigned_date | luxon("MMMM") }}
          </div>
          <div v-else class="preview_month">MONAT</div>
          <div class="preview_event mt-1" v-if="article.event_day_data">
            {{ article.event_day_data.name }}
          </div>
        </div>
        <div class="preview_rbr mt-1">
          Bibellese:
          {{ article.recommended_bible_reference }}
        </div>
        <div class="preview_votd_box">
          <div class="preview_votd">
            <div>
              {{ this.hyphenated_votd }}
              <span class="preview_votd_ref">{{ votd_ref }}</span>
            </div>
          </div>
        </div>
        <div
          class="preview_text preview_text_style page_one"
          :style="`${this.hyphenated_votd.length >= 80 ? 'top: 37px;' : ''}`"
        >
          <h2 class="preview_title">{{ article.title }}</h2>
          <div
            style="text-align:right; position:absolute; left: -28px; background-color:gold; padding: 0 2px;"
            v-if="show_lines"
            class="preview_text_style"
          >
            1<br />2<br />3<br />4<br />5<br />6<br />7<br />
            <span style="color: darkgreen"> 8<br />9<br />10 </span>
            <br />
            <span style="color: darkorange">
              11
            </span>
            <span style="color: darkred"> <br />12</span><br />
          </div>
          <div v-html="this.hyphenated_text" id="output_text"></div>
        </div>
      </div>
    </div>
    <div class="preview_container print-card">
      <div class="text-center">
        <v-progress-circular
          v-if="loading"
          class="my-15"
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
      <div class="preview_content" v-if="!loading">
        <div class="preview_text preview_text_style page_two">
          <div
            style="text-align:right; position:absolute; left: -28px; background-color:gold; padding: 0 2px;"
            v-if="show_lines"
            class="preview_text_style"
          >
            13<br />14<br />15<br />16<br />17<br />18<br />19<br />20<br />21<br />22<br />23<br />
            <span style="color: darkgreen"> 24<br />25<br />26 </span>
            <br />
            <span style="color: darkorange">
              27
            </span>
            <span style="color: darkred"> <br />28</span><br />
          </div>
          <div v-html="this.hyphenated_text" id="output_text"></div>
          <div
            class="preview_author_name"
            v-if="article.writer_data !== undefined"
          >
            {{ article.writer_data.short }}
          </div>
        </div>
        <div class="preview_bottom_grid">
          {{ this.hyphenated_learning_verse }}
          <div class="preview_lv_ref">
            {{ article.learning_verse_ref }}
          </div>
          <hr style="position: relative; top: -24px" />
          <div style="position: relative; top: -24px">
            {{ this.hyphenated_learning_verse }}
            <div class="preview_lv_ref">
              {{ article.learning_verse_ref }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hyphenate } from "hyphen/de"
import bibleRefMixin from "@/mixins/bibleRefMixin"

export default {
  name: "ArticlePrintLayout",
  props: {
    article: Object,
    show_lines: Boolean,
    show_ids: Boolean,
    loading: Boolean,
  },
  mixins: [bibleRefMixin],
  data() {
    return {
      hyphenated_text: "",
      hyphenated_votd: "",
      hyphenated_learning_verse: "",
      votd_ref: "",
    }
  },
  created() {
    if (!this.loading) this.prepareContent()
  },
  watch: {
    loading(loading) {
      if (!loading) {
        this.prepareContent()
      }
    },
  },
  methods: {
    prepareContent() {
      hyphenate(this.article.text, { minWordLength: 5 }).then((result) => {
        this.hyphenated_text = result
      })
      hyphenate(this.article.votd, { minWordLength: 5 }).then((result) => {
        this.hyphenated_votd = result
      })
      hyphenate(this.article.learning_verse, { minWordLength: 5 }).then(
        (result) => {
          this.hyphenated_learning_verse = result
        }
      )
      this.votd_ref = this.parseBibleRef(
        this.article.votd_reference,
        this.article.votd_reference_overwrite
      )
    },
  },
}
</script>

<style scoped>
.preview_container {
  width: 365px;
  height: 470px;
  background-color: white;
  box-shadow: black 1px 1px 4px;
  padding: 25px 36px 35px 29px;
  color: #333;
}

.preview_content {
  position: relative;
  height: 100%;
}

.preview_date_box {
  /* font-family: "Montserrat", sans-serif; */
  /* display: grid;
  grid-template-columns: auto auto; */
  position: relative;
  height: 100px;
  width: 90%;
}

.preview_weekday {
  font-family: "Wurper-Regular", sans-serif;
  position: absolute;
  top: 35px;
  left: 120px;
  font-size: 15pt;
  line-height: 17pt;
}

.preview_day {
  font-family: "Vanilla", sans-serif;
  font-weight: 600;
  font-size: 56pt;
  line-height: 67.2pt;
  letter-spacing: -0.6pt;
  z-index: 2;
}

.preview_calender_page {
  position: relative;
  top: -80px;
  left: -25%;
  height: 27.688mm;
  width: 22.039mm;
  z-index: -1;
}

.preview_month {
  font-family: "Wurper-Regular", sans-serif;
  text-align: right;
  top: 15px;
  left: 120px;
  font-size: 15pt;
  line-height: 17pt;
  position: absolute;
}

.preview_year {
  font-family: "Wurper-Regular", sans-serif;
  font-size: 12pt;
  line-height: 14.4pt;
  letter-spacing: normal;
  position: relative;
  top: 10px;
  left: 7.5px;
}

.preview_event {
  font-family: "Lato-Regular", sans-serif;
  font-style: italic;
  position: absolute;
  top: 50px;
  left: 120px;
  font-size: 8pt;
  line-height: 11.7pt;
  letter-spacing: -0.6pt;
}

.preview_rbr {
  font-family: "Lato-Regular", sans-serif;
  font-size: 8pt;
  line-height: 9.6pt;
  background-color: #eee;
  height: 20px;
}

/* .preview_votd_box { */
/* font-family: "Montserrat", sans-serif; */
/* border-style: dashed;
  border-color: #333;
  border-width: 1px 0;
  margin: 5px 0;
  padding: 5px 0;
  display: grid;
  grid-template-columns: 30px 1fr 30px; */
/* } */

/* .preview_votd_img{
  height: 60px;
  margin-left: -12px;
} */

.preview_votd_box {
  font-family: "Lato-Bold", sans-serif;
  height: 60px;
  padding-top: 15px;
}

.preview_votd {
  font-family: "Lato-Bold", sans-serif;
  font-weight: 800;
  font-size: 11pt;
  line-height: 14.4pt;
}

.preview_votd_ref {
  font-family: "Lato-Regular", sans-serif;
  padding-top: 4px;
  font-weight: 400;
  font-size: 8pt;
  display: block;
  text-align: end;
  line-height: 9.6pt;
}

.preview_title {
  font-family: "Lato-Bold", sans-serif;
  font-size: 11pt;
  letter-spacing: -0.6pt;
  text-align: left;
  margin-top: 14px;
  font-weight: 800;
  margin-bottom: 2px;
  line-height: 14.4pt;
}

.preview_topic_img {
  position: absolute;
  left: -15px;
  top: -10px;
  height: 9.4mm;
  width: 12.48mm;
}

.preview_article_img {
  height: 100px;
  width: 140px;
}

.preview_text_img_floater {
  float: left;
  width: 9mm;
  height: 2mm;
}

.preview_text {
  /* position: relative; */
  white-space: pre-line;
}

.page_one {
  margin-top: -10px;
  max-height: 65%;
  overflow: hidden;
}

.page_two {
  max-height: 75%;
  overflow: hidden;
  #output_text {
    transform: translateY(-230px);
  }
}

.preview_text_style {
  font-family: "Lato-Regular", serif;
  /* font-size: 12.7px; Value that looks the same on all Browsers. Even Chrome on Windows!! :D */
  /* font-size: 0.877em; */
  font-size: 10pt;
  line-height: 14.4pt;
  letter-spacing: -0.5pt;
}

.v-input--selection-controls {
  margin-top: 0;
}

#output_text {
  text-align: justify;
  /* letter-spacing: normal; */
}

.preview_author_name {
  font-family: "Lato-Regular", serif;
  text-align: right;
  position: relative;
  font-size: 8pt;
  line-height: 9.6pt;
  bottom: 182pt;
}

.preview_bottom_grid {
  font-family: "Lato-Regular", sans-serif;
  font-size: 9pt;
  line-height: 14.4pt;
  /* display: grid; */
  /* grid-template-columns: max-content 1fr; */
  /* grid-gap: 6px; */
  position: relative;
}

.preview_bottom_grid div {
  height: 26px;
}

.preview_lv_ref {
  text-align: right;
  position: relative;
  font-size: 8pt;
  line-height: 12pt;
  top: -16pt;
  height: 50%;
}

#preview_date_line {
  position: relative;
  top: 15px;
}

@media print {
  .print-card {
    margin-top: 75px;
    margin-bottom: 100px;
  }
}
</style>

<style>
#output_text p {
  margin-bottom: 0;
}

#output_text p:not(:first-child) {
  text-indent: 0px;
}

#output_text p:after {
  content: "";
  display: inline-block;
  width: 0;
}
</style>
