<template>
  <v-app id="inspire">
    <MenuSetDefault />

    <v-main>
      <v-card :loading="loading" flat>
        <v-card-title>
          Beitrag erstellen
          <v-select
            :items="availableProjects"
            v-model="selectedProject"
            returnObject
            item-text="name"
            label="Jahr"
            hide-details
            single-line
            solo
            style="max-width: 10%;"
            class="mx-2 elevation-0"
          />
          <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Picker without buttons"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="new Date(date).toLocaleString('en-CH').slice(0, 10)"
                style="max-width: 10%;"
                class="mx-2 elevation-0"
                solo
                hide-details
              />
            </template>
            <v-date-picker
              v-model="date"
              :min="`${selectedProject.year}-01-01`"
              :max="`${selectedProject.year}-12-31`"
              :allowed-dates="allowedDates"
            />
          </v-menu>
        </v-card-title>

        <v-card-subtitle>
          mit * gekennzeichnete Felder sind Pflichtfelder.
        </v-card-subtitle>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="7">
                  <span class="form-header">Inhalt</span>
                  <v-divider class="mb-3" />

                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="form_title"
                        :rules="[(v) => !!v || 'Titel ist ein Pflichtfeld']"
                        label="Titel*"
                        required
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span class="form-header">Beitragstext*</span>
                      <tiptap-vuetify
                        @init="onInitTiptap"
                        :card-props="{
                          flat: true,
                          color: '#d8dad7',
                          'min-height': 300,
                        }"
                        :toolbar-attributes="{ color: 'primary', dark: true }"
                        v-model="form_text"
                        :extensions="extensions"
                      />
                      <small
                        :class="
                          this.htmlToText(form_text).length >
                          instanceData.articleTextLength
                            ? 'text--warn'
                            : ''
                        "
                        >{{ this.htmlToText(form_text).length }}/{{
                          instanceData.articleTextLength
                        }}</small
                      >
                    </v-col>

                    <v-col cols="12" v-if="instanceData.articleQuestionField">
                      <v-text-field
                        v-model="form_question"
                        label="Frage"
                        filled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" v-if="instanceData.articleHintField">
                      <v-text-field
                        v-model="form_hint"
                        label="Tipp"
                        filled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-if="!instanceData.hasReadingPlan"
                        v-model="form_recommended_bible_ref"
                        label="Bibellesestelle"
                        filled
                        class="mb-4"
                        hide-details
                      ></v-text-field>
                      <v-text-field
                        v-if="instanceData.hasReadingPlan"
                        v-model="form_recommended_bible_ref"
                        label="Bibellesestelle"
                        filled
                        class="mb-4"
                        hide-details
                      ></v-text-field>
                      <v-text-field
                        v-if="instanceData.hasReadingPlan"
                        v-model="form_learning_verse"
                        label="Lernvers"
                        filled
                        class="mb-4"
                        hide-details
                      ></v-text-field>
                      <v-text-field
                        v-if="instanceData.hasReadingPlan"
                        v-model="form_learning_verse_ref"
                        label="Lernversposition"
                        filled
                        class="mb-4"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="5">
                  <span class="form-header">Vers des Tages</span>
                  <v-divider class="mb-3" />

                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="votd_book"
                        :rules="[(v) => !!v || 'Buch ist ein Pflichtfeld']"
                        :items="votd_books"
                        item-value="number"
                        item-text="name"
                        label="Buch*"
                        required
                        filled
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-autocomplete
                        v-model="translation"
                        :rules="[
                          (v) => !!v || 'Übersetzung ist ein Pflichtfeld',
                        ]"
                        :items="translations"
                        item-value="id"
                        item-text="name_full"
                        label="Übersetzung*"
                        required
                        filled
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pb-0">
                      <v-autocomplete
                        v-model="votd_chapter"
                        :rules="[(v) => !!v || 'Kapitel ist ein Pflichtfeld']"
                        :items="votd_chapters"
                        item-text="chapter"
                        label="Kapitel*"
                        required
                        filled
                      />
                    </v-col>

                    <v-col cols="4" class="pb-0">
                      <v-autocomplete
                        v-model="votd_verse"
                        :rules="[(v) => !!v || 'Vers ist ein Pflichtfeld']"
                        :items="votd_verses"
                        label="Vers*"
                        required
                        filled
                      />
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <v-autocomplete
                        v-model="votd_verse_end"
                        :rules="[
                          (v) =>
                            !v ||
                            v > this.votd_verse ||
                            'Vers Ende muss größer sein als der Beginn',
                        ]"
                        :items="votd_verses"
                        label="Vers Ende"
                        clearable
                        filled
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-right py-0">
                      <v-btn
                        text
                        x-small
                        color="secondary"
                        dark
                        @click="showVotdRefOverwrite = !showVotdRefOverwrite"
                      >
                        Benutzerdefinierte Tagesvers Bibelstelle
                      </v-btn>
                      <v-text-field
                        v-if="showVotdRefOverwrite"
                        v-model="form_votd_reference_overwrite"
                        label="Benutzerdefinierte Tagesvers Bibelstelle"
                        filled
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <small v-if="bibleServerURL"
                        >Übereinstimmung prüfen:
                        <a target="_blank" :href="bibleServerURL">
                          {{ decodeURI(bibleServerURL) }}
                        </a>
                      </small>

                      <v-textarea
                        v-model="form_votd"
                        :rules="[(v) => !!v || 'Tagesvers ist ein Pflichtfeld']"
                        label="Tagesvers*"
                        counter="180"
                        rows="2"
                        auto-grow
                        filled
                        required
                      />

                      <v-checkbox
                        v-if="instanceData.hasVotdExcerpt"
                        v-model="form_votd_is_excerpt"
                        class="mt-0"
                        label="Tagesvers ist Auszug"
                      />
                    </v-col>
                  </v-row>

                  <span class="form-header">Thema</span>
                  <v-divider class="mb-3" />

                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-if="instanceData.hasContentTopics"
                        label="Arbeitstitel"
                        v-model="contentTopic"
                        :items="contentTopics"
                        item-text="name"
                        filled
                        class="mb-4"
                        hide-details
                        return-object
                      >
                        <template v-slot:append-outer>
                          <v-btn icon @click="newContentTopic = true">
                            <v-icon>mdi-plus-thick</v-icon>
                          </v-btn>
                        </template>
                      </v-select>
                      <TopicSelection v-model="topic" />
                      <DialogNewContentTopic
                        :dialog="newContentTopic"
                        @close="useNewContentTopic"
                      />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="eventTopic"
                        :items="eventTopics"
                        item-value="id"
                        item-text="name"
                        label="Anlass"
                        clearable
                        filled
                        class="mb-2"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="event"
                        :items="events"
                        item-value="id"
                        item-text="name"
                        label="Ereignis"
                        clearable
                        filled
                        class="mb-2"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="12" v-if="!this.$store.getters.isEditor">
                      <v-textarea
                        v-model="comment_author"
                        label="Kommentar"
                        rows="2"
                        auto-grow
                        filled
                        class="mb-2"
                        hide-details
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="
                        this.$store.getters.isEditor ||
                          this.$store.getters.isAdmin
                      "
                    >
                      <v-autocomplete
                        v-model="author"
                        :items="authors"
                        item-value="id"
                        item-text="full_name"
                        label="Autor"
                        filled
                        class="mb-2"
                        hide-details
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="
                        this.$store.getters.isEditor ||
                          this.$store.getters.isAdmin
                      "
                    >
                      <v-text-field
                        v-model="reusedArticle"
                        label="Wiedervewendeter Artikel (ID)"
                        filled
                        append-outer-icon="mdi-magnify"
                        @click:append-outer="openArchive"
                        class="mb-2"
                        hide-details
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="
                        (this.$store.getters.isEditor ||
                          this.$store.getters.isAdmin) &&
                          this.$store.getters.currentProjectMeta.year <
                            new Date().getFullYear()
                      "
                    >
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="DateTime.fromISO(date).toLocaleString()"
                            label="Datum"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mb-2"
                            hide-details
                          />
                        </template>
                        <v-date-picker
                          v-model="date"
                          :active-picker.sync="activePicker"
                          :min="
                            `${this.$store.getters.currentProjectMeta.year}-01-01`
                          "
                          :max="
                            `${this.$store.getters.currentProjectMeta.year}-12-31`
                          "
                          color="primary"
                          @change="saveDate"
                        />
                      </v-menu>
                    </v-col>

                    <!-- <v-col cols="12" class="text-right"> -->
                    <!-- <v-divider class="mb-3" />
                      <v-spacer />
                      <v-btn color="primary" text @click="reset"
                        >Zurücksetzen</v-btn
                      >
                      <v-btn color="primary" text @click="close"
                        >Schließen</v-btn
                      >
                      <v-btn color="primary" elevation="0" @click="submit"
                        >Speichern</v-btn
                      > -->
                    <!-- </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-divider />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="reset">Zurücksetzen</v-btn>
          <v-btn color="primary" text @click="close">Schließen</v-btn>
          <v-btn color="primary" elevation="0" @click="submit">Speichern</v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="archiveDialog"
        width="300"
        @click:outside="cancelArchiveDialog"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Beitrag aus dem Archiv nutzen
          </v-card-title>
          <v-card-text class="text-center">
            Geben Sie die ID des Beitrags ein, den sie nutzen möchten:
            <v-text-field v-model.number="reusedArticle" />
            <v-btn @click="getArchiveArticle">Beitrag suchen</v-btn>
            <div v-if="Object.keys(archiveArticle).length > 0">
              {{ archiveArticle.title }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="previewItem()" v-bind="attrs" v-on="on">
                    <v-icon>mdi-text-box-search-outline</v-icon>
                  </v-btn>
                </template>
                <span>Vorschau</span>
              </v-tooltip>
              <v-btn @click="useArchiveArticle">
                Diesen Beitrag nutzen
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="cancelArchiveDialog">
              abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <DialogPreviewFull
        v-if="reusedArticle && reusedArticle.length > 0"
        :dialog="previewDialog"
        :article_id="reusedArticle"
        @close="closePreview"
      />
    </v-main>
  </v-app>
</template>

<script>
import MenuSetDefault from "@/components/layout/MenuSetDefault"
import { RepositoryFactory } from "@/repositories/RepositoryFactory"

import {
  TiptapVuetify,
  Bold,
  Italic,
} from /*
  BulletList,
  OrderedList,
  ListItem,
  Paragraph
  */ "tiptap-vuetify"
import DialogPreviewFull from "@/components/articles/DialogPreviewFull"

import textModifyMixin from "@/mixins/textModifyMixin"
import bibleRefMixin from "@/mixins/bibleRefMixin"
import projectMixin from "@/mixins/projectMixin"
import DateTime from "luxon/src/datetime.js"
import Interval from "luxon/src/interval.js"

import DialogNewContentTopic from "../../components/articles/DialogNewContentTopic"
import TopicSelection from "../../components/articles/TopicSelection"

const BibleTranslationRepository = RepositoryFactory.get("bible_translations")
const TopicsRepository = RepositoryFactory.get("topics")
const EventsRepository = RepositoryFactory.get("events")
const ArticlesRepository = RepositoryFactory.get("articles")
const BibleRepository = RepositoryFactory.get("bible")
const UsersRepository = RepositoryFactory.get("users")
const ReadingPlanRepository = RepositoryFactory.get("readingPlans")
const ProjectsRepository = RepositoryFactory.get("projects")

export default {
  name: "HistorialArticlesCreation",
  components: {
    MenuSetDefault,
    TiptapVuetify,
    TopicSelection,
    DialogPreviewFull,
    DialogNewContentTopic,
  },
  props: {},
  mixins: [textModifyMixin, bibleRefMixin, projectMixin],
  data() {
    return {
      loading: false,
      newText: "",
      translations: [],
      translation: null,
      workTitle: null,
      topics: [],
      subTopics: [],
      topic: null,
      events: [],
      event: null,
      authors: [],
      author: null,
      eventTopics: [],
      eventTopic: null,
      votd_book: null,
      votd_books: [],
      votd_chapter: null,
      votd_chapters: [],
      votd_verse: null,
      votd_verse_end: null,
      votd_verses: [],
      valid: true,
      form_title: "",
      form_text: "",
      form_votd: "",
      form_votd_reference_overwrite: "",
      form_votd_is_excerpt: "",
      form_question: "",
      form_hint: "",
      form_recommended_bible_ref: "",
      form_learning_verse: "",
      form_learning_verse_ref: "",
      comment_author: "",
      tiptapEditor: null,
      tiptapIsValid: false,
      tiptapText: "",
      tiptapIsEmpty: false,
      activePicker: null,
      date: null,
      project: null,
      dateMenu: false,
      extensions: [
        Bold,
        Italic,
        // ListItem,
        // BulletList,
        // OrderedList,
        // Paragraph,
      ],
      showVotdRefOverwrite: false,
      DateTime,
      TopicSelection,
      readingPlan: {},
      selectedProject: "",
      availableProjects: [],
      datePickerMenu: false,
      availableDates: [],
      reusedArticle: "",
      archiveArticle: {},
      archiveDialog: false,
      previewDialog: false,
      contentTopics: [],
      contentTopic: null,
      newContentTopic: false,
      disableTopic: false,
    }
  },
  async created() {
    await this.fetchAvailableProjects()
    await this.fetchProjectArticles()
    await this.fetchTranslations()
    await this.fetchTopics()
    await this.fetchEvents()
    await this.fetchEventTopics()
    await this.fetchUsers()
    await this.fetchBooks()
  },
  methods: {
    async fetchTranslations() {
      this.loading = true
      const { data } = await BibleTranslationRepository.get()
      this.translations = data
      this.loading = false
    },
    async fetchUsers() {
      this.loading = true
      const { data } = await UsersRepository.get()
      this.authors = data
      this.loading = false
    },
    async fetchTopics() {
      this.loading = true
      const { data: topicsData } = await TopicsRepository.get()
      this.topics = topicsData
      const { data: subTopicsData } = await TopicsRepository.getSubTopics()
      this.subTopics = subTopicsData
      const {
        data: contentTopicsData,
      } = await TopicsRepository.getContentTopics()
      this.contentTopics = contentTopicsData
      this.contentTopics = this.contentTopics
        .filter((e) => e.active == true)
        .sort(function(a, b) {
          return a.name.localeCompare(b.name)
        })
        .sort(function(a, b) {
          return a.topic_name.localeCompare(b.topic_name)
        })
      this.loading = false
    },
    async fetchEvents() {
      this.loading = true
      const { data } = await EventsRepository.get()
      this.events = data
      this.loading = false
    },
    async fetchEventTopics() {
      this.loading = true
      const { data } = await EventsRepository.getEventTopics()
      this.eventTopics = data
      this.loading = false
    },
    async fetchProjectArticles() {
      const { data } = await ArticlesRepository.getByProject(
        this.selectedProject.id
      )
      this.projectArticles = data
    },
    async fetchAvailableProjects() {
      const { data } = await ProjectsRepository.getActiveProjects()
      this.availableProjects = data
      this.selectedProject = this.availableProjects.at(-1)
      this.project = this.availableProjects.at(-1).id
    },
    async getFirstEmptyDate() {
      this.date = this.availableDates[0].date
    },
    async getAvailableDates() {
      this.availableDates = []
      const startDate = DateTime.local(this.selectedProject.year, 1, 1)
      const endDate = DateTime.local(this.selectedProject.year, 12, 31)

      const datesArray = Interval.fromDateTimes(
        startDate.startOf("day"),
        endDate.endOf("day")
      )
        .splitBy({ day: 1 })
        .map((date) => ({ date: date.start.toISODate(), available: true }))

      for (let article in this.projectArticles) {
        article = this.projectArticles[article]
        let dateIndex = datesArray.findIndex(
          (date) => date.date == article.assigned_date
        )
        datesArray[dateIndex].available = false
      }

      if (datesArray.filter((date) => date.available == true).length > 0) {
        for (let date in datesArray.filter((date) => date.available == true)) {
          date = datesArray.filter((date) => date.available == true)[date]
          this.availableDates.push(date)
        }
      } else {
        this.availableProjects.pop()
        this.selectedProject = this.availableProjects.at(-1)
        this.project = this.availableProjects.at(-1).id
      }
    },
    allowedDates(date) {
      return this.availableDates.findIndex((aDate) => aDate.date == date) != -1
    },
    addDays(date, amount) {
      var tzOff = date.getTimezoneOffset() * 60 * 1000,
        t = date.getTime(),
        d = new Date(),
        tzOff2

      t += 1000 * 60 * 60 * 24 * amount
      d.setTime(t)

      tzOff2 = d.getTimezoneOffset() * 60 * 1000
      if (tzOff != tzOff2) {
        var diff = tzOff2 - tzOff
        t += diff
        d.setTime(t)
      }

      return d
    },
    setSelectedTopic(contentTopic) {
      let topicIdentifier = contentTopic.topic

      if (contentTopic.sub_topic) {
        topicIdentifier = topicIdentifier + "-" + contentTopic.sub_topic
      }

      if (
        this.topics.filter((topic) => topic.identifier == topicIdentifier)
          .length > 0
      ) {
        this.topic = this.topics.filter(
          (topic) => topic.identifier == topicIdentifier
        )[0]
      } else {
        this.topic = this.subTopics.filter(
          (subTopic) => subTopic.identifier == topicIdentifier
        )[0]
      }
    },
    async getReadingPlan() {
      this.loading = true
      const { data: readingPlanData } = await ReadingPlanRepository.getByDate(
        this.date
      )
      this.form_recommended_bible_ref =
        readingPlanData.recommended_bible_reference
      this.form_learning_verse = readingPlanData.learning_verse
      this.form_learning_verse_ref = readingPlanData.learning_verse_ref
      this.loading = false
    },
    async submit() {
      this.tiptapText = this.htmlToText(this.tiptapEditor.getHTML())
      let newContentTopic = null

      if (
        typeof this.contentTopic === "string" ||
        this.contentTopic instanceof String
      ) {
        let topicIdentifiers = this.topic.identifier.split("-")
        newContentTopic = await TopicsRepository.addContentTopic(
          this.contentTopic,
          topicIdentifiers[0],
          topicIdentifiers[1]
        )
      }
      // else {
      //   this.setSelectedTopic()
      // }

      this.tiptapIsEmpty = this.tiptapText === ""
      this.tiptapIsValid = !this.tiptapIsEmpty
      if (!(this.$store.getters.isEditor || this.$store.getters.isAdmin)) {
        this.author = false
        this.form_text = this.replaceSpecialChars(this.form_text)
      }
      if (this.form_text)
        this.form_text = this.replaceSpecialChars(this.form_text)
      if (this.form_question)
        this.form_question = this.replaceSpecialChars(this.form_question)
      if (this.form_hint)
        this.form_hint = this.replaceSpecialChars(this.form_hint)
      if (this.form_recommended_bible_ref)
        this.form_recommended_bible_ref = this.replaceSpecialChars(
          this.form_recommended_bible_ref
        )
      if (this.form_votd)
        this.form_votd = this.replaceSpecialChars(this.form_votd)
      if (this.form_title)
        this.form_title = this.replaceSpecialChars(this.form_title)

      if (this.$refs.form.validate() && this.tiptapIsValid) {
        await ArticlesRepository.add(
          this.form_title,
          this.form_text,
          this.form_votd,
          this.votd_book +
            ";" +
            this.votd_chapter +
            ";" +
            this.votd_verse +
            ";" +
            (this.votd_verse_end === undefined || this.votd_verse_end == null
              ? ""
              : this.votd_verse_end),
          this.translation,
          this.topic?.identifier ? this.topic.identifier : false,
          newContentTopic ? newContentTopic.data.id : this.contentTopic?.id,
          this.form_question,
          this.form_hint,
          this.eventTopic,
          this.event,
          this.form_recommended_bible_ref,
          this.form_learning_verse,
          this.form_learning_verse_ref,
          this.form_votd_reference_overwrite,
          this.form_votd_is_excerpt,
          this.author,
          this.comment_author,
          this.project,
          this.date,
          this.reusedArticle,
          40
        )
        await this.$store.dispatch(
          "setMessage",
          "Der Eintrag wurde erfolgreich eingereicht."
        )
        this.reset()
        this.disableTopic = false
        await this.fetchProjectArticles()
        await this.getAvailableDates()
        await this.getFirstEmptyDate()
        await this.fetchTopics()
      } else {
        await this.$store.dispatch(
          "setMessage",
          "Speichern nicht möglich. Es fehlen noch Pflichtangaben."
        )
      }
    },
    reset() {
      this.$refs.form.reset()
      this.form_text = ""
    },
    async fetchBooks() {
      this.loading = true
      const { data } = await BibleRepository.getBooks()
      this.votd_books = data
      this.loading = false
    },
    async fetchChapters(book_id) {
      this.loading = true
      const { data } = await BibleRepository.getChaptersByBook(book_id)
      this.votd_chapters = data
      if (
        this.votd_chapter == null ||
        this.votd_chapter > this.votd_chapters.length
      ) {
        this.votd_chapter = null
        this.votd_verses = []
        this.votd_verse = null
      } else {
        this.fill_verse_options(this.votd_chapter)
      }
      this.loading = false
    },
    fill_verse_options(chapter_id) {
      this.votd_verses = []
      let versesAmount = this.votd_chapters.find((obj) => {
        return obj.chapter === chapter_id
      }).verse_count
      for (let i = 1; i <= versesAmount; i++) {
        this.votd_verses.push(i)
      }
      if (this.votd_verse != null && this.votd_verse > versesAmount) {
        this.votd_verse = null
      }
      if (this.votd_verse_end != null && this.votd_verse_end > versesAmount) {
        this.votd_verse_end = null
      }
    },
    async fetchEventDaysByTopic(topic_id) {
      this.loading = true
      const { data } = await EventsRepository.getDays(topic_id)
      this.events = data
      this.loading = false
    },
    onInitTiptap(e) {
      this.tiptapEditor = e.editor
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, "")
    },
    close() {
      this.$emit("close")
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    openArchive() {
      this.archiveDialog = true
    },
    async getArchiveArticle() {
      const { data } = await ArticlesRepository.getArticle(this.reusedArticle)
      this.archiveArticle = data
    },
    useArchiveArticle() {
      this.archiveDialog = false
      this.form_title = this.archiveArticle.title
      this.form_text = this.archiveArticle.text
      this.form_votd = this.archiveArticle.votd

      let votdReference = this.archiveArticle.votd_reference.split(";")

      this.votd_book = Number(votdReference[0])
      this.votd_chapter = Number(votdReference[1])
      this.votd_verse = Number(votdReference[2])
      if (votdReference[3] != "") {
        this.votd_verse_end = Number(votdReference[3])
      }

      this.translation = this.archiveArticle.votd_bible_translation
      // this.topic?.identifier ? this.topic.identifier : false
      this.contentTopic = this.archiveArticle.content_topic_data
      this.form_question = this.archiveArticle.question
      this.form_hint = this.archiveArticle.hint

      if (this.archiveArticle.event_day_topic) {
        this.eventTopic = Number(this.archiveArticle.event_day_topic)
      } else {
        this.eventTopic = null
      }
      if (this.archiveArticle.event_day) {
        this.event = Number(this.archiveArticle.event_day)
      } else {
        this.event = null
      }

      this.form_recommended_bible_ref = this.archiveArticle.recommended_bible_reference
      this.form_learning_verse = this.archiveArticle.learning_verse
      this.form_learning_verse_ref = this.archiveArticle.learning_verse_ref
      this.form_votd_reference_overwrite = this.archiveArticle.votd_reference_overwrite
      this.author = this.archiveArticle.writer
      this.comment_author = this.archiveArticle.comment_author
      this.reused_article = this.archiveArticle.id
    },
    cancelArchiveDialog() {
      this.reusedArticle = ""
      this.archiveArticle = {}
      this.archiveDialog = false
    },
    previewItem() {
      this.previewDialog = true
    },
    closePreview() {
      this.previewDialog = false
    },
    async useNewContentTopic(nct = null) {
      if (nct) {
        this.contentTopic = nct

        const {
          data: contentTopicsData,
        } = await TopicsRepository.getContentTopics()
        this.contentTopics = contentTopicsData
      }

      this.newContentTopic = false
    },
  },
  computed: {
    validReference: {
      get: function() {
        if (this.votd_book && this.votd_chapter && this.votd_verse) {
          let verseEnd = ""
          if (
            this.votd_verse_end !== undefined &&
            this.votd_verse_end !== null &&
            this.votd_verse_end > this.votd_verse
          ) {
            verseEnd = this.votd_verse_end
          }
          return this.parseBibleRef(
            this.votd_book +
              ";" +
              this.votd_chapter +
              ";" +
              this.votd_verse +
              ";" +
              verseEnd
          )
        } else {
          return false
        }
      },
    },
    bibleServerURL: {
      get: function() {
        if (this.validReference && this.translation) {
          let translationName = this.translations.find(
            (x) => x.id === this.translation
          ).name
          if (["andere"].includes(translationName)) return false
          if (translationName == "ELB CSV") {
            let invalidReference = this.validReference
            invalidReference = invalidReference.replace(".", "")
            invalidReference = invalidReference.split(" ")
            let newValidReference = invalidReference.join("-")
            return encodeURI(
              `https://www.csv-bibel.de/bibel/${newValidReference}`
            )
          }
          return encodeURI(
            `https://www.bibleserver.com/${translationName}/${this.validReference}`
          )
        } else {
          return false
        }
      },
    },
  },
  watch: {
    async selectedProject(newValue) {
      this.project = this.availableProjects.find((e) => e == newValue).id
      await this.fetchProjectArticles()
      await this.getAvailableDates()
      await this.getFirstEmptyDate()
    },
    votd_book(newValue) {
      if (newValue !== null) this.fetchChapters(newValue)
    },
    votd_chapter(newValue) {
      if (this.votd_chapters.length > 0 && this.votd_chapter != null) {
        this.fill_verse_options(newValue)
      } else if (this.votd_chapter == null) {
        this.votd_verse = null
        this.votd_verse_end = null
      }
    },
    eventTopic(new_value) {
      if (new_value !== null && typeof new_value !== "undefined") {
        this.fetchEventDaysByTopic(new_value)
      } else {
        this.fetchEvents()
      }
    },
    contentTopic(newValue) {
      if (newValue != null && typeof newValue === "object") {
        this.setSelectedTopic(newValue)
        this.disableTopic = true
      } else {
        this.disableTopic = false
      }
    },
  },
}
</script>

<style>
.text--warn {
  color: #c01500;
}

.form-header {
  color: #333333;
}
</style>
