import Repository from "./Repository";

const resource = "/topics";

export default {
    get() {
        return Repository.get(`${resource}/`);
    },
    getSubTopics() {
        return Repository.get(`/sub_topics/`);
    },
    add(name, img_b64) {
        let payload = { 'name': name, 'img_b64': img_b64 }
        return Repository.post(`${resource}/`, payload);
    },
    addSubTopic(name, img_b64, topic) {
        let payload = { 'name': name, 'img_b64': img_b64, 'topic': topic }
        return Repository.post(`/sub_topics/`, payload);
    },
    update(id, name, img_b64) {
        let payload = { 'name': name, 'img_b64': img_b64 };

        return Repository.put(`${resource}/${id}/`, payload);
    },
    updateSubTopic(id, name, img_b64, topic) {
        let payload = { 'name': name, 'img_b64': img_b64, 'topic': topic };

        return Repository.put(`/sub_topics/${id}/`, payload);
    },
    getContentTopics() {
        return Repository.get('/content_topics/')
    },
    getContentTopic(ID) {
        return Repository.get(`/content_topics/${ID}/`)
    },
    async addContentTopic(name, topic, subTopic = false) {
        let payload = { 'name': name, 'topic': topic }

        if (subTopic) {
            payload.sub_topic = subTopic
        }

        return Repository.post('/content_topics/', payload)
    },
    async editContentTopic(ID, name, topic, subTopic = false) {
        let payload = { 'name': name, 'topic': topic }

        if (subTopic) {
            payload.sub_topic = subTopic
        } else {
            payload.sub_topic = null
        }

        return Repository.patch(`/content_topics/${ID}/`, payload)
    },
    async toggleContentTopicActiveState(ID, active) {
        return Repository.patch(`/content_topics/${ID}/`, { 'active': active })
    }
}