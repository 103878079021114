<template>
  <v-app id="inspire">
    <MenuSetDefault />

    <v-main>
      <v-card class="elevation-0">
        <v-toolbar dense color="accent" class="elevation-0">
          <v-toolbar-title>Arbeitstitel</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-switch
            class="ativeSwitch"
            v-model="showInactive"
            label="Zeige inaktive"
            hide-details
            @change="filterContentTopics"
          />

          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
              <v-btn
                v-bind="tooltipAttrs"
                v-on="tooltip"
                @click="editItem()"
                absolute
                dark
                fab
                bottom
                right
                color="primary"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Arbeitstitel erstellen</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-title>
          <v-text-field
            style="max-width: 250px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Suchen"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :loading="loading"
          loading-text="Daten werden geladen... Bitte warten"
          :headers="headers"
          :items="contentTopics"
          :items-per-page="15"
          :search="search"
          :sort-by="['topic_name', 'name']"
          multi-sort
        >
          <template v-slot:top>
            <DialogNewContentTopic
              v-if="dialog"
              :dialog="dialog"
              :editedItem="editedItem"
              :formTitle="formTitle"
              :editedIndex="editedIndex"
              @close="close"
            />
            <DialogContentTopicDelete
              :dialogDelete="dialogDelete"
              :editedIndex="editedIndex"
              :deactivate="editedItem.active"
              dialogTitle="Arbeitstitel wirklich entfernen?"
              @deleteItemConfirm="deleteItemConfirm"
              @closeDelete="closeDelete"
            />
          </template>
          <template v-slot:item.id="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon plain>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="editItem(item)">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-square-edit-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Arbeitstitel bearbeiten
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteItem(item)" v-if="item.active">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Arbeitstitel Deaktivieren
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteItem(item)" v-else>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-plus-thick
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Arbeitstitel Reaktivieren
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import MenuSetDefault from "@/components/layout/MenuSetDefault"
import { RepositoryFactory } from "@/repositories/RepositoryFactory"
import DialogContentTopicDelete from "@/components/settings/DialogContentTopicDelete"
import DialogNewContentTopic from "@/components/articles/DialogNewContentTopic"

const ContentTopicsRepository = RepositoryFactory.get("topics")

export default {
  name: "ContentTopics",
  components: {
    MenuSetDefault,
    DialogContentTopicDelete,
    DialogNewContentTopic,
  },
  data() {
    return {
      isShowForm: false,
      name: "",
      name_full: "",
      search: "",
      contentTopics: [],
      loading: true,
      headers: [
        { text: "Name", value: "name" },
        { text: "Thema", value: "topic_name" },
        { text: "Unterthema", value: "sub_topic_name" },
        { text: "Anwendungen", value: "uses", align: "right" },
        { text: "Aktionen", value: "id", align: "right" },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        name: "",
        topic: "",
        subTopic: "",
      },
      defaultItem: {
        id: null,
        name: "",
        topic: "",
        subTopic: "",
      },
      showInactive: false,
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const { data } = await ContentTopicsRepository.getContentTopics()
      this.contentTopics = data
      this.loading = false
    },
    editItem(item) {
      if (item === undefined) {
        this.editedItem = Object.assign({}, this.defaultItem)
      } else {
        this.editedIndex = this.contentTopics.indexOf(item)
        this.editedItem = Object.assign({}, item)
      }
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.contentTopics.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await ContentTopicsRepository.toggleContentTopicActiveState(
        this.editedItem.id,
        !this.editedItem.active
      )
      this.filterContentTopics()
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.filterContentTopics()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async filterContentTopics() {
      if (this.showInactive) {
        await this.fetch()
      } else {
        await this.fetch()
        this.contentTopics = this.contentTopics.filter((e) => e.active == true)
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Neuen Arbeitstitel anlegen"
        : "Arbeitstitel bearbeiten"
    },
  },
  async created() {
    this.filterContentTopics()
  },
}
</script>

<style>
.ativeSwitch {
  position: relative;
  right: 75px;
}
</style>
