import Repository from "./Repository";

const resource = "/song_texts";

export default {
    get() {
        return Repository.get(`${resource}/`);
    },
    getByNumber(number) {
        return Repository.get(`${resource}/${number}/`);
    },
    getBySearch(search) {
        return Repository.get(`${resource}/?search=${search}`)
    }
}
