import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

import Login from '../views/Login'
import Profile from "../views/user/Profile";
import MyProfile from "../views/user/MyProfile";
import Dashboard from '../views/Dashboard'
import Articles from '../views/Articles'
import UnassignedArticles from "../views/articles/UnassignedArticles";
import MyArticles from '../views/articles/MyArticles'
import MyArticle from '../views/articles/MyArticle'
import UserArticles from '../views/articles/UserArticles'
import Article from '../views/articles/Article'
import Calendar from "../views/Calendar";
import Events from '../views/Events'
import Projects from '../views/Projects'
import Topics from '../views/Topics'
import Churches from '../views/settings/Churches'
import BibleTranslations from "../views/settings/BibleTranslations";
import Users from '../views/Users'
import Export from '../views/Export'
import ArticlesSearch from "../views/articles/ArticlesSearch";
import ArticlesArchive from "../views/articles/ArticlesArchive";
import ArticlesPreview from "../views/articles/ArticlesPreview";
import ArticlesPreviewPrint from "../views/articles/ArticlesPreviewPrint";
import Project from '../views/Project'
import ReadingPlan from '../views/ReadingPlan'
import ArticlePlanning from '../views/ArticlePlanning'
import HistoricalArticleCreation from '../views/articles/HistoricalArticlesCreation'
import ContentTopics from '../views/settings/ContentTopics';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Übersicht',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/me',
        name: 'Mein Profil',
        component: MyProfile,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/profile/:userId',
        name: 'Profil',
        component: Profile,
        meta: {
            requiresAuth: true,
            requiredAdmin: true,
        }
    },
    {
        path: '/articles',
        name: 'Beiträge',
        component: Articles,
        meta: {
            requiresEditor: true,
            requiresProject: true,
        }
    },
    {
        path: '/unassigned-articles',
        name: 'Nicht zugeordnete Beiträge',
        component: UnassignedArticles,
        meta: {
            requiresEditor: true,
        }
    },
    {
        path: '/article-search',
        name: 'Beitragssuche',
        component: ArticlesSearch,
        meta: {
            requiresEditor: true,
        }
    },
    {
        path: '/article-archive',
        name: 'Beitragsarchiv',
        component: ArticlesArchive,
        meta: {
            requiresEditor: true,
        }
    },
    {
        path: '/my-articles',
        name: 'Meine Beiträge',
        component: MyArticles,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/my-articles/:articleId',
        name: 'Mein Beitrag',
        component: MyArticle,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/user-articles/:userId',
        name: 'Benutzerbeiträge',
        component: UserArticles,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/articles/:articleId',
        name: 'Beitrag',
        component: Article,
        meta: {
            requiresAuth: true,
            requiresProject: true,
            requiresEditor: true,
        }
    },
    {
        path: '/project/articles-preview',
        name: 'Projektvorschau',
        component: ArticlesPreview,
        meta: {
            requiresAuth: true,
            requiresProject: true,
            requiresEditor: true,
        }
    },
    {
        path: '/project/articles-preview-print',
        name: 'ProjektvorschauDruck',
        component: ArticlesPreviewPrint,
        meta: {
            requiresAuth: true,
            requiresProject: true,
            requiresEditor: true,
        }
    },
    {
        path: '/calendar',
        name: 'Kalender',
        component: Calendar,
        meta: {
            requiresAuth: true,
            requiresEditor: true,
            requiresProject: true,
        }
    },
    {
        path: '/events',
        name: 'Ereignisse',
        component: Events,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/projects',
        name: 'Projekte',
        component: Projects,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/project',
        name: 'Projekt',
        component: Project,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/topics',
        name: 'Themen',
        component: Topics,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/churches',
        name: 'Gemeinden',
        component: Churches,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/translations',
        name: 'Übersetzungen',
        component: BibleTranslations,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'Benutzer',
        component: Users,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
        }
    },
    {
        path: '/export',
        name: 'Export',
        component: Export,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/readingplan',
        name: 'Leseplan',
        component: ReadingPlan,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/articleplanning',
        name: 'Beitragsplanung',
        component: ArticlePlanning,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/historical-articles',
        name: "Beitragschronik",
        component: HistoricalArticleCreation,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
    {
        path: '/content-topics',
        name: "Arbeitstitel",
        component: ContentTopics,
        meta: {
            requiresAdmin: true,
            requiresAuth: true
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path === '/login' && store.getters.isLoggedIn) {
        next('/')
    }

    if (to.matched.some(record => record.meta.requiresProject && (store.getters.isAdmin || store.getters.isEditor))) {
        if (store.getters.currentProject === null) {
            next('/');
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (store.getters.isLoggedIn) {
            if (to.matched.some(record => record.meta.requiresAdmin)) {
                if (store.getters.isAdmin) {
                    next();
                    return
                }
                next('/');
                return
            }
            if (to.matched.some(record => record.meta.requiresEditor)) {
                if (store.getters.isEditor) {
                    next();
                    return
                }
                next('/');
                return
            }
            next();
        } else {
            store.dispatch('setRedirect', to);
            next('/login');
        }
    } else {
        next();
    }
});

export default router
