<template>
  <v-dialog
    v-model="dialogDelete"
    max-width="350px"
    @click:outside="closeDelete"
    @keydown.esc="closeDelete"
  >
    <v-card>
      <v-card-title v-if="deactivate">
        Arbeitstitel wirklich entfernen?
      </v-card-title>
      <v-card-title v-else>Arbeitstitel wieder aktivieren?</v-card-title>
      <v-card-text v-if="deactivate">
        Der Eintrag wird dabei nicht gelöscht, sondern nur deaktiviert. Das ist
        notwendig um historische Daten auch weiterhin korrekt zu erhalten.
        Deaktivierte Arbeitstitel werden bei der Arbeitstitelauswahl in der
        Beitragserstellung/-bearbeitung nicht angezeigt.
      </v-card-text>
      <v-card-text v-else>
        Der Eintrag wird wieder in der Arbeitstitelauswahl in der
        Beitragserstellung/-bearbeitung angezeigt.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
        <v-btn color="primary" elevation="0" @click="deleteItemConfirm">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogContentTopicDelete",
  props: {
    dialogDelete: Boolean,
    editedIndex: Number,
    dialogTitle: String,
    deactivate: Boolean,
  },
  methods: {
    deleteItemConfirm() {
      this.$emit("deleteItemConfirm")
    },
    closeDelete() {
      this.$emit("closeDelete", this.editedIndex)
    },
  },
}
</script>

<style scoped></style>
