<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-if="instanceData.hasContentTopics"
          label="Arbeitstitel"
          v-model="contentTopic"
          filled
          class="mb-4"
          hide-details
        />
        <TopicSelection v-model="topic" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="editOrCreate" color="primary">Speichern</v-btn>
        <v-btn @click="close" text>Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import projectMixin from "@/mixins/projectMixin"

import TopicSelection from "../articles/TopicSelection"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"

const TopicsRepository = RepositoryFactory.get("topics")

export default {
  name: "DialogNewContentTopic",
  props: {
    dialog: Boolean,
    formTitle: String,
    editedItem: Object,
  },
  components: { TopicSelection },
  mixins: [projectMixin],
  data() {
    return {
      contentTopics: [],
      contentTopic: "",
      topic: {},
    }
  },
  async mounted() {
    if (this.editedItem) {
      this.contentTopic = this.editedItem.name
      if (this.editedItem.sub_topic !== null) {
        this.topic = {
          id: this.editedItem.sub_topic,
          topic_name: this.editedItem.topic_name,
          identifier: this.editedItem.topic + "-" + this.editedItem.sub_topic,
          name: this.editedItem.sub_topic_name,
          img_b64: null,
          topic: this.editedItem.topic,
        }
      } else {
        this.topic = {
          id: this.editedItem.topic,
          sub_topics: [],
          identifier: this.editedItem.topic,
          name: this.editedItem.topic_name,
          img_b64: null,
        }
      }
    }
  },
  methods: {
    close() {
      this.resetForm()
      this.$emit("close")
    },
    editOrCreate() {
      if (this.editedItem?.id) {
        this.edit()
      } else {
        this.create()
      }
    },
    async edit() {
      if (typeof this.topic.identifier == "string") {
        let topicIdentifiers = this.topic.identifier.split("-")

        await TopicsRepository.editContentTopic(
          this.editedItem.id,
          this.contentTopic,
          topicIdentifiers[0],
          topicIdentifiers[1]
        )
      } else {
        await TopicsRepository.editContentTopic(
          this.editedItem.id,
          this.contentTopic,
          this.topic.identifier,
          0
        )
      }

      this.resetForm()
      this.$emit("close")
    },
    async create() {
      let topicIdentifiers = this.topic.identifier.split("-")
      let newContentTopic = await TopicsRepository.addContentTopic(
        this.contentTopic,
        topicIdentifiers[0],
        topicIdentifiers[1]
      )

      this.resetForm()
      this.$emit("close", newContentTopic.data)
    },
    resetForm() {
      this.contentTopic = ""
      this.topic = {}
    },
  },
}
</script>

<style scoped></style>
